import * as React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Table, Timeline } from 'antd';
import "antd/dist/antd.css";

import Layout from "../components/layout"
import Seo from "../components/seo"
import { ListItem } from '../components/common/lists'
import useHeadings from '../lib/useHeadings'
import { price } from '../lib/tables'
import Information from '../components/common/information'

const AboutPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  const tableOfContents = useHeadings();

  return (
    <Layout location={location} title={siteTitle} toc={tableOfContents}>
      <Seo title="当院について" />

      <h1>当院について</h1>
      <h2> 連絡先 </h2>
        <Information />

      <h2 id="院内の様子"> 院内の様子</h2>

        <StaticImage
          className=""
          layout="constrained"
          breakpoints= {[599, 1024, 1920]}
          formats={["auto", "webp", "avif"]}
          src="../images/waiting-room-landscape.jpg"
          quality={95}
          alt="待合室の様子"
        />
        <p> 広めの待合室なので、くつろいで診察を待つことができます。</p>

        <StaticImage
          className=""
          layout="fullWidth"
          formats={["auto", "webp", "avif"]}
          src="../images/meeting-room-landscape.jpg"
          quality={95}
          alt="診察室の様子"
        />
        <p> 遮音された診察室では、診療のプライバシーが守られます。 </p>

      <h2 id="診療科目">診療科目</h2>

      精神科・心療内科を中心に診療をおこなっています。
      以下のような病気が主な診療対象になります。

      <ListItem 
        items={[
          "不眠症", 
          "不安障害", 
          "適応障害", 
          "うつ病、うつ状態", 
          "統合失調症",
          "双極性感情障害(躁うつ病)",
          "認知症"
          ]}
        >
      </ListItem>


      <h2 id="診療内容"> 診療内容 </h2>

      当院では、主に以下のような内容の診療を行っています。

      <ListItem 
        depth={0}
        items={[
          "診察(精神療法)", 
          "医薬品の処方",
          "採血(注射や点滴はできません)",
          "各種診断書の発行", 
          [
            "職場や学校などに提出する診断書",
            "障害年金、障害者福祉手帳",
            "自立支援医療診断書",
            "介護保険主治医意見書",
            "その他(ハローワーク主治医意見書、成年後見制度診断書など)"
          ],
        ]} >
      </ListItem>

      <p>
      公的保険が適用されない各種診断書等の自費診療について、以下のような価格を設定しています(<a href="/2024-01-03-price-revision"> 2024年1月3日改訂</a>)。
      </p>

       <Table 
          dataSource={price.ownExpense.dataSource} 
          columns={price.ownExpense.columns} 
          size="small" 
          pagination={false} 
          bordered 
        />

      <br />


      <h2 id="院長からのご挨拶"> 院長からのご挨拶 </h2>

        <figure className="text-center">
          <StaticImage
            className="bio-avatar"
            layout="fixed"
            formats={["auto", "webp", "avif"]}
            src="../images/face.png"
            width={150}
            height={150}
            quality={95}
            style={{display: "block", marginLeft: "auto", marginRight: "auto", marginBottom: `1.45rem`}}
            alt="院長の顔写真"
          />
          <figcaption>
            院長 立川察理 
          </figcaption>
        </figure>
        <p>
        開院から5年近くが経過し、少しずつメンタルクリニックとしての経験と知見を蓄えてきました。
        それとともに、当院の治療方針も徐々に確立されてきました。
        今回のWebサイトの刷新にともない、その治療方針をここで紹介することにしました。
        </p>
        <p>
        当院は、問題解決型の診療を目指します。
        問題解決型の診療とは、患者さんが困っている症状や問題点を特定し、その軽減や改善を目指すという診療です。
        それは、対症療法としての薬物治療だけの医療ではありません。
        症状の背景にある問題点を探り出し、可能であればその問題自体を改善することを意味します。
        </p>

        <p>
        この問題解決型の診療とは、いわゆる「寄り添う」医療とは異なります。
        医療者が患者さんを一方的に支えるというものではなく、医療者と患者さんがともに問題の解決を目指すというあり方です。
        </p>

        <p>
        そのためには、まず医療者自身(すなわち私)が患者本人の主張に耳を傾けて、病状の背後にある問題点を根気よく明らかにしなければなりません。
        一方で患者さん側にも協力してもらわなければなりません。
        患者さんは、問題の核心を簡潔かつ十分に説明する必要があります。
        両者の作業がうまく噛みあえば、この治療方針は成果をあげることができるでしょう。
        </p>


      <h3 id="略歴">略歴</h3>

      <Timeline style={{marginTop: "2rem", marginLeft: "3rem"}}>
        <Timeline.Item>福岡県北九州市出身</Timeline.Item>
        <Timeline.Item>1991/03  東京大学法学部卒業</Timeline.Item>
        <Timeline.Item>2002/03  長崎大学医学部卒業</Timeline.Item>
        <Timeline.Item>2002/05～2003/04　長崎大学医学部附属病院(研修医)</Timeline.Item>
        <Timeline.Item>2003/04～2004/03　北九州市立八幡病院(研修医)</Timeline.Item>
        <Timeline.Item>2004/04～2008/03　八幡厚生病院（精神科）</Timeline.Item>
        <Timeline.Item>2008/06～2010/10　成増厚生病院（精神科）</Timeline.Item>
        <Timeline.Item>2010/11～2014/03　東京大学医学部附属病院(医療情報学)</Timeline.Item>
        <Timeline.Item>2014/04～2014/07　東北大学病院（医療情報学）</Timeline.Item>
        <Timeline.Item>2014/09～2017/08　緑ヶ丘病院（精神科）</Timeline.Item>
        <Timeline.Item>2017/12より、           仙台東口メンタルクリニックを開院　</Timeline.Item>
      </Timeline>

      <h3 id="資格">資格・免許</h3>
        <ListItem 
          depth={0}
          items={[
            "医師免許", 
            "精神保健指定医",
            "産業医",
            "普通自動車免許",
            "特殊小型船舶免許"
          ]} >
        </ListItem>



      <h3 id="趣味">趣味</h3>

      <h4 id="読書">読書</h4>

      <p>
      ヨーロッパの近代小説が好みで、一番好きな作家は<strong>ドストエフスキー</strong>です。特に<a href="https://www.amazon.co.jp/%E3%82%AB%E3%83%A9%E3%83%9E%E3%83%BC%E3%82%BE%E3%83%95%E3%81%AE%E5%85%84%E5%BC%9F-%E7%AC%AC4%E5%B7%BB-%E5%B2%A9%E6%B3%A2%E6%96%87%E5%BA%AB-%E8%B5%A4-615-2/dp/4003261526/ref=sr_1_19?ie=UTF8&qid=1515640059&sr=8-19&keywords=%E3%82%AB%E3%83%A9%E3%83%9E%E3%83%BC%E3%82%BE%E3%83%95%E3%81%AE%E5%85%84%E5%BC%9F"> 『カラマーゾフの兄弟』 </a>は、私が人生でもっとも衝撃を受けた作品です。
      </p>

      <h4 id="硬式テニス">硬式テニス</h4>

      <p>
      仙台市内の某テニススクールに週1回ほど通っています。腕前は大したことありません。
      </p>

      <h4>コンピュータ・プログラミング</h4>

      <p>
      今まで、Ruby, Scala, JavaScript などを中心に趣味でプログラミングをしてきました。
      最近ではもっぱら JavaScript でWeb系アプリーケーションを作成しています。
      </p>

      <h2 id="著作">著作</h2>

      <p>
      2016年秋、長年の趣味が高じて書籍<a href="https://www.amazon.co.jp/%E9%96%A2%E6%95%B0%E5%9E%8B%E3%83%97%E3%83%AD%E3%82%B0%E3%83%A9%E3%83%9F%E3%83%B3%E3%82%B0%E3%81%AE%E5%9F%BA%E7%A4%8E-JavaScript%E3%82%92%E4%BD%BF%E3%81%A3%E3%81%A6%E5%AD%A6%E3%81%B6-%E7%AB%8B%E5%B7%9D%E5%AF%9F%E7%90%86/dp/4865940596/ref=pd_bxgy_14_img_2?_encoding=UTF8&psc=1&refRID=Q21KSC0A2K12GHR877N8" > 『関数型プログラミングの基礎』</a> を出版しました。JavaScriptを用いて関数型プログラミングの考え方を紹介したものです。
    </p>

      { /* BookImage() */ }

      <StaticImage
        className=""
        layout="fixed"
        formats={["auto", "webp", "avif"]}
        src="../images/book-cover.png"
        width={133}
        quality={95}
        style={{display: "block", marginLeft: "auto", marginRight: "auto", marginBottom: `1.45rem`}}
        alt="本の表紙"
      />

      <p>
        東京大学在籍中に医療情報学の研究をまとめた未発表論文です。<a href="https://ja.wikipedia.org/wiki/%E5%9E%8B%E4%BB%98%E3%81%8D%E3%83%A9%E3%83%A0%E3%83%80%E8%A8%88%E7%AE%97"> 型付λ計算 Typed Lambda Calculus の理論 </a> を利用して、医療情報の国際規格 <a href="https://www.iso.org/standard/67868.html"> ISO13606 </a> を検証するという内容となっています。
      </p>
      <figure className="text-center">
        <a href="https://www.academia.edu/39123793/An_analysis_of_structural_interoperability_in_ISO_13606_based_on_typed_lambda_calculus"> 
        <StaticImage
          className=""
          layout="fixed"
          formats={["auto", "webp", "avif"]}
          src="../images/archetype-semantics.png"
          width={300}
          quality={98}
          style={{display: "block", border: "1px solid", marginLeft: "auto", marginRight: "auto", marginBottom: `1.45rem`}}
          alt="論文の表紙"
        />
        </a>
        <figcaption>
           An analysis of structural interoperability in ISO 13606 based on typed lambda calculus
        </figcaption>
      </figure>

    </Layout>
  )
}

export default AboutPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
    // allFile {
    //   edges {
    //     node {
    //       name
    //       childImageSharp {
    //         gatsbyImageData(layout: CONSTRAINED)
    //       }
    //     }
    //   }
    // }
